import React, { useContext } from "react";
import { tour } from "../../../pano";
import {
  tourParamsContext,
  controlsContext,
  ContainerNameType,
} from "../../../context";
import { Grid, TextField, Divider } from "@material-ui/core";
import { setResponsiveControlValue } from "../../../helper";
import { Control } from "../../../types";

interface TextfieldInputProps {
  control: Control;
  containerName: ContainerNameType;
}

const TextfieldInput: React.FC<TextfieldInputProps> = ({
  control,
  containerName,
}) => {
  const tourParams = useContext(tourParamsContext);
  const controls = useContext(controlsContext);
  return (
    <Grid container style={{ padding: "10px" }}>
      <Grid item xs={12}>
        <TextField
          id={control.id}
          onChange={(event) => {
            setResponsiveControlValue(
              control,
              event.target.value,
              tourParams.active_viewport,
              containerName,
              controls,
              controls.setControls,
              tour
            );
          }}
          value={
            tourParams.active_viewport === "desktop"
              ? control.value
              : tourParams.active_viewport === "tablet"
              ? control.value_tablet || control.value
              : control.value_mobile || control.value
          }
          color="primary"
          label={control.label}
        />
        <Divider />
      </Grid>
    </Grid>
  );
};

export default TextfieldInput;
