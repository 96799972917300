/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext } from "react";
import { tour } from "../../../pano";
import {
  tourParamsContext,
  controlsContext,
  ContainerNameType,
} from "../../../context";
import {
  Grid,
  Slider,
  Divider,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { setResponsiveControlValue } from "../../../helper";
import { SliderControl } from "../../../types";
import styled from "styled-components";

interface SliderInputProps {
  className?: string;
  control: SliderControl;
  containerName: ContainerNameType;
}

const SliderInputFunc: React.FC<SliderInputProps> = ({
  className,
  control,
  containerName,
}) => {
  const tourParams = useContext(tourParamsContext);
  const controls = useContext(controlsContext);
  return (
    <Grid container className={className}>
      <Grid item xs>
        <InputLabel color="secondary" id={control.id + "_label"}>
          {control.label}
        </InputLabel>
        {/* @ts-ignore */}
        <Slider
          id={control.id}
          onChange={(_e, value) => {
            const parsedVal = typeof value === "number" ? value : value[0];
            setResponsiveControlValue(
              control,
              parsedVal.toFixed(control.decimals),
              tourParams.active_viewport,
              containerName,
              controls,
              controls.setControls,
              tour
            );
          }}
          value={
            tourParams.active_viewport === "desktop"
              ? control.value
              : tourParams.active_viewport === "tablet"
              ? control.value_tablet || control.value
              : control.value_mobile || control.value
          }
          step={control.step}
          min={control.min}
          max={control.max}
          valueLabelDisplay="auto"
          //component={div}
        />
        <Input
          id={control.id}
          onChange={(event) => {
            setResponsiveControlValue(
              control,
              parseFloat(event.target.value).toFixed(control.decimals),
              tourParams.active_viewport,
              containerName,
              controls,
              controls.setControls,
              tour
            );
          }}
          value={
            tourParams.active_viewport === "desktop"
              ? parseFloat(control.value)
              : tourParams.active_viewport === "tablet"
              ? parseFloat(control.value_tablet || control.value)
              : parseFloat(control.value_mobile || control.value)
          }
          type="number"
        />
        <FormHelperText>{control.helperText}</FormHelperText>
        <Divider />
      </Grid>
    </Grid>
  );
};
const SliderInput = styled(SliderInputFunc)`
padding:8px;
`;

export default SliderInput;
