import { TourbuilderFuncs } from "./context";
import { Tourbuilder } from "./types";

interface tourParams {
  basepath: string;
  node: string;
  fov?: number;
  pan?: number;
  tilt?: number;
  width: number | string;
  height: number | string;
  horizontalAlignment: "center" | "right" | "left";
}

// eslint-disable-next-line no-var
export var tour: Tourbuilder | null;

export const initTourVar = (id: string, classInstance: any): void => {
  tour = new classInstance(id);
};
export const getFunctionFromString = (funcName: TourbuilderFuncs): ((value: any) => any) | null => {
  if (tour) {
    return tour[funcName];
  } else {
    return null;
  }
};
export const initTour = (params: tourParams): void => {
  if (tour) {
    tour.setBasePath(params.basepath);
    tour.setStartNode(parseInt(params.node));
    tour.setFov(params.fov || 70);
    tour.setPan(params.pan || 0);
    tour.setTilt(params.tilt || 0);
    tour.setDimensions(params.width, params.height);
    tour.setHorizontalAlignment(params.horizontalAlignment);
    tour.setSingleImage(false);
    tour.setShareButtonVisibility(false);
    tour.setImpressumVisibility(true);
    tour.setDimensions_tablet("100%", "16:9");
    tour.setHorizontalAlignment_tablet("center");
    tour.setSingleImage_tablet(false);
    tour.setShareButtonVisibility_tablet(false);
    tour.setImpressumVisibility_tablet(false);
    tour.setDimensions_mobile("100%", "16:9");
    tour.setHorizontalAlignment_mobile("center");
    tour.setSingleImage_mobile(false);
    tour.setShareButtonVisibility_mobile(false);
    tour.setImpressumVisibility_mobile(false);
    tour.init();
  }
};
export const addTourListener = (listener: () => any, callback: () => any) => {
  const pano_ready_interval = setInterval(() => {
    if (tour && tour.pano && tour.pano.getIsLoaded()) {
      clearInterval(pano_ready_interval);
      tour.pano.addListener(listener, callback);
      console.log("added listener");
    }
  }, 10);
};

export const reloadTour = (params: { basepath: string; node: string }) => {
  if (tour && tour.pano) {
    tour.setBasePath(params.basepath);
    tour.setStartNode(parseInt(params.node));
    //tour.setDimensions("100%",params.yDim);
    tour.reload();
  }
};

export const updatePano = (params: any) => {
  for (const [key, value] of Object.entries(params)) {
    switch (key) {
      case "":
    }
  }
};
export const afterPanoLoad = (callback: () => any) => {
  const panoInterval = setInterval(() => {
    if (tour && tour.pano && tour.pano.getIsLoaded()) {
      clearInterval(panoInterval);
      callback();
    }
  }, 10);
};
export const onReloadListener = (callback: () => any) => {
  if (tour)
    tour.onReload = () => {
      callback();
    };
};
export const afterReloadListener = (callback: () => any) => {
  if (tour)
    tour.onReloadFinished = () => {
      callback();
    };
};
