import PanoPreview from "./containers/PanoPreview";
import ControlTabs from "./containers/ControlTabs";
import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  controlsContext,
  tourParamsContext,
  tourParamsContextProps,
} from "./context";
import OutputCode from "./containers/OutputCode";
import styled from "styled-components";

interface AppProps {
  className?: string;
}

const AppFunc: React.FC<AppProps> = ({ className }) => {
  const [tourParams, setTourParams] = useState<tourParamsContextProps>(
    useContext(tourParamsContext)
  );
  const tourParamsProviderValue = useMemo(() => {
    return { tourParams, setTourParams };
  }, [tourParams]);
  const [controls, setControls] = useState(useContext(controlsContext));
  const controlsProviderValue = useMemo(() => {
    return { controls, setControls };
  }, [controls]);
  useEffect(() => {
    setTourParams({ ...tourParams, setTourParams });
    setControls({ ...controls, setControls });
  }, []);
  return (
    <Grid container className={className} data-testid = "App-Container">
      <tourParamsContext.Provider value={tourParamsProviderValue.tourParams}>
        <controlsContext.Provider value={controlsProviderValue.controls}>
          <OutputCode />
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <ControlTabs />
          </Grid>
          <Grid item xs={6} sm={8} md={9} lg={10}>
            <PanoPreview />
          </Grid>
        </controlsContext.Provider>
      </tourParamsContext.Provider>
    </Grid>
  );
};

const App = styled(AppFunc)``;

export default App;
