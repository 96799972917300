/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from "react";
import { Tabs, Tab, Grid } from "@material-ui/core";
import ControlSections from "../ControlSections";
import { controlsTheme } from "../../Themes";
import { ThemeProvider } from "@material-ui/core/styles";
import ResponsiveSwitcher from "../ResponsiveSwitcher";
import "./index.css";
import { ControlTabKeys } from "../../types";

const ControlTabs: React.FC<{}> = ({}) => {
  const [activeTab, setActiveTab] = useState<ControlTabKeys>("tour");
  return (
    <ThemeProvider theme={controlsTheme}>
      <div className="ControlTabs-container">
        <Tabs
          value={activeTab}
          onChange={(event, val) => {
            setActiveTab(val);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            className="ControlTabs-Tab"
            value="tour"
            label="Tour-options"
            textColor="secondary"
          ></Tab>

          <Tab
            className="ControlTabs-Tab"
            value="movement"
            label="Movement"
            color="secondary"
          ></Tab>

          <Tab
            className="ControlTabs-Tab"
            value="style"
            label="Style"
            color="secondary"
          ></Tab>
        </Tabs>
        <div
          style={{
            minHeight: "calc( 100vh - 125px )",
            maxHeight: "calc( 100vh - 125px )",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <ControlSections activeTab={activeTab} />
        </div>
        <ResponsiveSwitcher />
      </div>
    </ThemeProvider>
  );
};

export default ControlTabs;
