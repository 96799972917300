import React, { useContext } from "react";
import { tour } from "../../../pano";
import { ContainerNameType, controlsContext } from "../../../context";
import { Grid, Button, Divider } from "@material-ui/core";

interface ValueSetterButtonProps {
  values: Record<string, ()=>unknown>;
  containerName: ContainerNameType;
}

const ValueSetterButton: React.FC<ValueSetterButtonProps> = ({
  values,
  containerName,
}) => {
  const controls = useContext(controlsContext);
  return (
    <Grid container style={{ padding: "10px" }}>
      <Grid item xs>
        <Button
          style={{ width: "100%", marginBottom: "20px" }}
          variant="contained"
          color="primary"
          onClick={() => {
            const newControls = [...controls[containerName]];
            for (const [key, value] of Object.entries(values)) {
              const index = newControls.findIndex(
                (newControl) => newControl.id === key
              );
              if (index !== -1) {
                newControls[index].value = value();
              } else {
                console.error("input with id " + key + " not found");
              }
            }
            if (controls.setControls)
              controls.setControls((state) => ({
                ...state,
                [containerName]: newControls,
              }));
          }}
        >
          Set to current Position
        </Button>
        <Divider />
      </Grid>
    </Grid>
  );
};
export default ValueSetterButton;
