import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {mainTheme} from './Themes';
import {ThemeProvider} from '@material-ui/core';

const rootElement = document.getElementById("root");


ReactDOM.render(
<ThemeProvider theme={mainTheme}>
      <App/>
  </ThemeProvider>,
rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
