import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { tour } from "../../pano";
import { tourParamsContext } from "../../context";
import styled from "styled-components";
import { Keyframe } from "../../types";

interface AddKeyFrameProps {
  className?: string;
}

const AddKeyframeFunc: React.FC<AddKeyFrameProps> = ({ className }) => {
  const tourParams = useContext(tourParamsContext);
  return (
    <Button
      data-testid="AddKeyframe-Wrapper"
      className={className}
      variant="contained"
      color="primary"
      onClick={() => {
        if (tour && tour.pano) {
          const newKeyframe: Keyframe = {
            id: Math.random().toString(36).substr(2, 9),
            active: true,
            fov: parseFloat(tour.pano.getFov().toFixed(2)),
            tilt: parseFloat(tour.pano.getTilt().toFixed(2)),
            pan: parseFloat(tour.pano.getPan().toFixed(2)),
            node: parseInt(tour.pano.getCurrentNode().substring(4)),
            speed: 1,
            move_lock: "Mousewheel",
          };
          if (tourParams.setTourParams)
            tourParams.setTourParams({
              ...tourParams,
              keyframes: [...tourParams.keyframes, newKeyframe],
            });
        }
      }}
    >
      add Keyframe
    </Button>
  );
};

const AddKeyframe = styled(AddKeyframeFunc)`
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export default AddKeyframe;
