/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext } from "react";
import { controlsContext } from "../../context";
import TextfieldInput from "../Inputs/TextfieldInput";

const HotspotStyleControls: React.FC<{}> = ({}) => {
  const controls = useContext(controlsContext);
  return (
    <div data-testid="HotspotStyleControls-Wrapper">
      {controls.hotspotStyle.map((control, i) => {
        switch (control.InputType) {
          case "textfield":
            return (
              <TextfieldInput
                key={control.id}
                control={control}
                containerName="hotspotStyle"
              />
            );
          default:
            console.error(`input type ${control.InputType} was not found`);
            return <p key={i}>input type {control.InputType} was not found</p>;
        }
      })}
    </div>
  );
};

export default HotspotStyleControls;
