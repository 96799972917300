import React, { useEffect, useState } from "react";
import { FormHelperText, Grid, Paper } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import TourControls from "../../components/TourControls";
import AddonControls from "../../components/AddonControls";
import MovementControls from "../../components/MovementControls";
import Keyframes from "../../components/Keyframes";
import AddKeyframe from "../../components/AddKeyframe";
import TourStyleControls from "../../components/TourStyleControls";
import HotspotStyleControls from "../../components/HotspotStyleControls";
import { controlsTheme } from "../../Themes";
import { ThemeProvider } from "@material-ui/core/styles";
import "./index.css";
import ControlSectionWrapper from "../../components/ControlSectionWrapper";
import { ControlTabKeys } from "../../types";

interface ControlSectionProps {
  activeTab: ControlTabKeys;
}

const ControlSections: React.FC<ControlSectionProps> = ({ activeTab }) => {
  switch (activeTab) {
    case "tour":
      return (
        <div className="ControlSections-section_container">
          <ThemeProvider theme={controlsTheme}>
            <ControlSectionWrapper defaultOpen label={"Tour"}>
              <TourControls />
            </ControlSectionWrapper>
            <ControlSectionWrapper label={"Addons"} responsive>
              <AddonControls />
            </ControlSectionWrapper>
          </ThemeProvider>
        </div>
      );
    case "movement":
      return (
        <div className="ControlSections-section_container">
          <ThemeProvider theme={controlsTheme}>
            <Paper className="ControlSections-section">
              <Grid container>
                <Grid item xs={10} xl={11}>
                  <FormHelperText id="movement_info">
                    Click the play-button in the top-left corner of the tour to
                    see the movement
                  </FormHelperText>
                </Grid>
                <Grid item xs={2} xl={1}>
                  <ErrorOutlineOutlinedIcon />
                </Grid>
              </Grid>
            </Paper>
            <ControlSectionWrapper defaultOpen label={"Movement"}>
              <MovementControls />
            </ControlSectionWrapper>
            <ControlSectionWrapper label={"Keyframes"}>
              <div>
                <Keyframes />
                <AddKeyframe />
              </div>
            </ControlSectionWrapper>
          </ThemeProvider>
        </div>
      );
    case "style":
      return (
        <div className="ControlSections-section_container">
          <ThemeProvider theme={controlsTheme}>
            <ControlSectionWrapper defaultOpen responsive label={"Tour Styles"}>
              <TourStyleControls />
            </ControlSectionWrapper>
            <ControlSectionWrapper label={"Hotspot Styles"}>
              <HotspotStyleControls />
            </ControlSectionWrapper>
          </ThemeProvider>
        </div>
      );
    default:
      return <p>tab not found</p>;
  }
};

export default ControlSections;
