import React, { useContext } from "react";
import { controlsContext } from "../../context";
import { NumberControl } from "../../types";
import NumberInput from "../Inputs/NumberInput";

const MovementControls: React.FC = () => {
  const controls = useContext(controlsContext);
  return (
    <div>
      {controls.movement.map((control, i) => {
        switch (control.InputType) {
          case "number":
            return (
              <NumberInput
                key={control.id}
                containerName="movement"
                control={control as NumberControl}
              />
            );
          default:
            console.error(`input type ${control.InputType} was not found`);
            return <p key={i}>input type {control.InputType} was not found</p>;
        }
      })}
    </div>
  );
};

export default MovementControls;
