import React, { useContext } from "react";
import { Grid, Divider, Button } from "@material-ui/core";
import TourSearchInput from "../TourSearchInput";
import TextfieldInput from "../Inputs/TextfieldInput";
import SliderInput from "../Inputs/SliderInput";
import NumberInput from "../Inputs/NumberInput";
import ValueSetterButton from "../Inputs/ValueSetterButton";
import { tour } from "../../pano";
import { controlsContext } from "../../context";
import { NumberControl, SliderControl } from "../../types";

const TourControls: React.FC = () => {
  const controls = useContext(controlsContext);
  return (
    <div>
      {controls.tour.map((control, i) => {
        switch (control.InputType) {
          case "basepath":
            return (
              <Grid
                key={control.id}
                container
                style={{ padding: "10px" }}
                direction="column"
              >
                <Grid item>
                  <Grid container>
                    <TourSearchInput />
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
              </Grid>
            );
          case "textfield":
            return (
              <Grid key={control.id} container style={{ padding: "10px" }}>
                <Grid item xs>
                  <TextfieldInput control={control} containerName="tour" />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    style={{ top: "16px", left: "8px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      tour?.reload();
                    }}
                  >
                    Update
                  </Button>
                </Grid>
                <Divider />
              </Grid>
            );
          case "slider":
            return (
              <SliderInput
                key={control.id}
                control={control as SliderControl}
                containerName="tour"
              />
            );
          case "number":
            return (
              <NumberInput
                key={control.id}
                control={control as NumberControl}
                containerName="tour"
              />
            );
          case "button":
            return (
              <ValueSetterButton
                key={control.id}
                values={{
                  fov_target: () => {
                    return tour?.pano?.getFov().toFixed(2) || 70;
                  },
                  tilt_target: () => {
                    return tour?.pano?.getTilt().toFixed(2) || 0;
                  },
                  pan_target: () => {
                    return tour?.pano?.getPan().toFixed(2) || 0;
                  },
                  node: () => {
                    return parseInt(
                      tour?.pano?.getCurrentNode().substring(4) || "1"
                    );
                  },
                }}
                containerName="tour"
              />
            );
          default:
            console.error(`input type ${control.InputType} was not found`);
            return <p key={i}>input type {control.InputType} was not found</p>;
        }
      })}
    </div>
  );
};

export default TourControls;
