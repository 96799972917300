import React, { useState, ReactElement } from "react";
import { Paper, Typography, Divider, Grid } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ActiveResponsiveIcon from "../ActiveResponsiveIcon";

interface ControlSectionWrapperProps {
  defaultOpen?: boolean;
  children: ReactElement;
  responsive?: boolean;
  label: string;
}

const ControlSectionWrapper: React.FC<ControlSectionWrapperProps> = ({
  defaultOpen,
  children,
  responsive,
  label,
}) => {
  const [open, setOpen] = useState<boolean>(!!defaultOpen);
  return (
    <Paper className="ControlSections-section">
      <div
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Grid container style={{ marginLeft: "1em" }}>
          <Grid item xs={1}>
            {open ? (
              <ArrowDropDownIcon
                color="secondary"
                style={{ float: "left", padding: 0 }}
              />
            ) : (
              <ArrowRightIcon
                color="secondary"
                style={{ float: "left", padding: 0 }}
              />
            )}
          </Grid>
          {responsive ? (
            <>
              <Grid item xs={10}>
                <Typography
                  variant="h5"
                  align="left"
                  color="secondary"
                  style={{ padding: "0.34em" }}
                  data-testid="ControlSectionWrapper-label"
                >
                  {label}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <div style={{ paddingRight: "16px" }}>
                  <ActiveResponsiveIcon />
                </div>
              </Grid>
            </>
          ) : (
            <Grid item xs={11}>
              <Typography
                variant="h5"
                align="left"
                color="secondary"
                style={{ padding: "0.34em" }}
                data-testid="ControlSectionWrapper-label"
              >
                {label}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Divider />
      </div>
      {open ? children : <></>}
    </Paper>
  );
};
export default ControlSectionWrapper;
