import React, { ReactNode, useContext } from "react";
import { controlsContext } from "../../context";
import CheckboxInput from "../Inputs/CheckboxInput";

const AddonControls: React.FC = () => {
  const controls = useContext(controlsContext);
  return (
    <div data-testid="AddonControls-Wrapper">
      {controls.addons.map((control, i) => {
        switch (control.InputType) {
          case "checkbox":
            return (
              <CheckboxInput
                key={control.id}
                control={control}
                containerName="addons"
              />
            );
          default:
            console.error(`input type ${control.InputType} was not found`);
            return <p key={i}>input type {control.InputType} was not found</p>;
        }
      })}
    </div>
  );
};
export default AddonControls;
