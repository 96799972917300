import { createContext } from "react";
import { ControlType, Keyframe } from "../types";

declare const window: any;

const params = new URL(window.location.href).searchParams;
export type TourbuilderFuncs =
  | "setActiveSingleImage"
  | "setActiveSingleImage_tablet"
  | "setActiveSingleImage_mobile"
  | "setShareButtonVisibility"
  | "setShareButtonVisibility_tablet"
  | "setShareButtonVisibility_mobile"
  | "setImpressumVisibility"
  | "setImpressumVisibility_tablet"
  | "setImpressumVisibility_mobile"
  | "setActiveHotspotColor"
  | "setMovementLoopAmount"
  | "setMovementDelay"
  | "setBasePath"
  | "setActiveNode"
  | "setActiveFov"
  | "setActiveTilt"
  | "setActivePan"
  | "setActiveWidth"
  | "setActiveWidth_tablet"
  | "setActiveWidth_mobile"
  | "setActiveHeight"
  | "setActiveHeight_tablet"
  | "setActiveHeight_mobile"
  | "horizontallyAlignImage"
  | "horizontallyAlignImage_tablet"
  | "horizontallyAlignImage_mobile";
export type ActiveViewport = "mobile" | "tablet" | "desktop";

export interface tourParamsContextProps {
  active_viewport: ActiveViewport;
  keyframes: Keyframe[];
  class360ty: { [key: string]: any };
  parentID: string;
  basepath: string;
  instance: any | undefined;
  codePanelOpen: boolean;
  setTourParams: React.Dispatch<
    React.SetStateAction<tourParamsContextProps>
  > | null;
}

export interface controlSectionsContextItem {
  visible: boolean;
}

export interface controlSectionsContextProps {
  tour: controlSectionsContextItem;
  addons: controlSectionsContextItem;
  keyframes: controlSectionsContextItem;
  movement_options: controlSectionsContextItem;
  tour_style: controlSectionsContextItem;
  hotspot_style: controlSectionsContextItem;
}
export interface controlsContextProps {
  addons: ControlType[];
  hotspotStyle: ControlType[];
  movement: ControlType[];
  tour: ControlType[];
  tourStyle: ControlType[];
  setControls: React.Dispatch<
    React.SetStateAction<controlsContextProps>
  > | null;
}
export type ContainerNameType =
  | "addons"
  | "hotspotStyle"
  | "movement"
  | "tour"
  | "tourStyle";
export const tourParamsContext = createContext<tourParamsContextProps>({
  active_viewport: "desktop",
  keyframes: [],
  class360ty: window["class_360ty"],
  parentID: "360ty_pano_container",
  basepath: params.get("basepath") || "https://lechwinter.360ty.cloud/",
  instance: undefined,
  codePanelOpen: false,
  setTourParams: null,
});
export const controlSectionsContext =
  createContext<controlSectionsContextProps>({
    tour: {
      visible: true,
    },
    addons: {
      visible: false,
    },
    keyframes: {
      visible: true,
    },
    movement_options: {
      visible: true,
    },
    tour_style: {
      visible: true,
    },
    hotspot_style: {
      visible: false,
    },
  });
export const defaultControlsContext: controlsContextProps = {
  addons: [
    {
      InputType: "checkbox",
      id: "single_image",
      label: "Remove Hotspots",
      helperText: "removes the clickable Hotspots, that allow the user to move through the tour",
      value: false,
      value_tablet: false,
      value_mobile: false,
      tourbuilderFunction: "setActiveSingleImage",
      requiresReload: false,
    },
    {
      InputType: "checkbox",
      id: "share_buttons",
      label: "Share Buttons",
      helperText:
        "creates share-buttons, that allow to share the exact position of the tour as a link or on facebook",
      value: true,
      value_tablet: false,
      value_mobile: false,
      tourbuilderFunction: "setShareButtonVisibility",
      requiresReload: true,
    },
    {
      InputType: "checkbox",
      id: "show_impressum",
      label: "Impressum",
      helperText:
        "creates a link on the bottom-right corner that links to our Homepage",
      value: true,
      value_tablet: false,
      value_mobile: false,
      tourbuilderFunction: "setImpressumVisibility",
      requiresReload: true,
    },
  ],
  hotspotStyle: [
    {
      InputType: "textfield",
      id: "hotspot_color",
      label: "Pulse Color",
      value: "",
      helperText: "The color of the Hotspot Pulses in HEX-Code (#FFFFFF)",
      tourbuilderFunction: "setActiveHotspotColor",
      requiresReload: false,
    },
  ],
  movement: [
    {
      InputType: "number",
      id: "loop_amount",
      label: "Loop Amount",
      step: 1,
      value: 1,
      decimals: 0,
      helperText: "How many times the Movement should loop through",
      tourbuilderFunction: "setMovementLoopAmount",
      requiresReload: false,
    },
    {
      InputType: "number",
      id: "delay",
      label: "Delay",
      step: 100,
      decimals: 0,
      value: 0,
      helperText:
        "The delay, after which the Movement should start, after the pano is loaded",
      tourbuilderFunction: "setMovementDelay",
      requiresReload: false,
    },
  ],
  tour: [
    {
      InputType: "basepath",
      id: "basepath",
      label: "Basepath",
      value: params.get("basepath") || "https://lechwinter.360ty.cloud",
      helperText:
        "base URI of the tour, where the pano2vr tour files are located. (https://*/)",
      requiresReload: true,
      tourbuilderFunction: "setBasePath",
    },
    {
      InputType: "button",
      id: "valuesetter_target",
      value: false,
    },
    {
      InputType: "number",
      id: "node",
      label: "start Node",
      value: params.get("node")
        ? isNaN(parseInt(params.get("node") as string)) === false
          ? parseInt(params.get("node") as string)
          : (params.get("node") as string).substring(
              4,
              (params.get("node") as string).length
            )
        : 1,
      helperText: "ID of the node, where the tour should start.",
      requiresReload: false,
      decimals: 0,
      step: 1,
      tourbuilderFunction: "setActiveNode",
    },
    {
      InputType: "slider",
      id: "fov_target",
      label: "FOV",
      value: params.get("fov") ? parseFloat(params.get("fov") as string) : 65.0,
      helperText:
        "Field of View/Zoom value of the Starting Postion of the Tour in degree",
      step: 0.1,
      decimals: 2,
      min: 5,
      max: 120,
      requiresReload: false,
      tourbuilderFunction: "setActiveFov",
    },
    {
      InputType: "slider",
      id: "tilt_target",
      label: "Tilt",
      helperText:
        "Vertical value of the Starting Postion of the Tour in degree",
      value: params.get("tilt") ? parseFloat(params.get("tilt") as string) : 0,
      step: 0.1,
      min: -90,
      max: 90,
      decimals: 2,
      requiresReload: false,
      tourbuilderFunction: "setActiveTilt",
    },
    {
      InputType: "slider",
      id: "pan_target",
      label: "Pan",
      helperText:
        "Horizontal value of the Starting Postion of the Tour in degree",
      value: params.get("pan") ? parseFloat(params.get("pan") as string) : 0,
      step: 0.1,
      min: -360,
      max: 360,
      decimals: 2,
      requiresReload: false,
      tourbuilderFunction: "setActivePan",
    },
    /*
        {
          InputType : "slider",
          id : "roll",
          label : "Roll",
          helperText :  "Tilts the tour over by x degree",
          value : 0,
          step : 0.1,
          min : -360,
          max : 360,
        }
        */
  ],
  tourStyle: [
    {
      InputType: "textfield",
      id: "tour_width",
      label: "Width",
      value: "100%",
      value_tablet: "100%",
      value_mobile: "100%",
      helperText: "Width of the Tour. Uses CSS units (%, px, vw)",
      requiresReload: false,
      tourbuilderFunction: "setActiveWidth",
    },
    {
      InputType: "select",
      id: "tour_height",
      label: "Aspect Ratio/Height",
      value: "21:9",
      value_tablet: "16:9",
      value_mobile: "16:9",
      menuItems: [
        {
          value: "1:1",
          label: "1:1",
        },
        {
          value: "3:2",
          label: "3:2",
        },
        {
          value: "4:3",
          label: "4:3",
        },
        {
          value: "16:9",
          label: "16:9",
        },
        {
          value: "21:9",
          label: "21:9",
        },
        {
          value: "9:16",
          label: "9:16",
        },
        {
          value: "custom_ratio",
          label: "custom Aspect Ratio",
        },
        {
          value: "custom_height",
          label: "custom Height",
        },
      ],
      helperText: "choose 'custom Height' to use CSS units",
      custom_ratio: "3:4",
      custom_height: "100%",
      custom_ratio_tablet: "3:4",
      custom_height_tablet: "100%",
      custom_ratio_mobile: "3:4",
      custom_height_mobile: "100%",
      requiresReload: false,
      tourbuilderFunction: "setActiveHeight",
    },
    {
      InputType: "select",
      id: "horizontal_alignment",
      label: "Horizontal Alignment",
      value: "center",
      value_tablet: "center",
      value_mobile: "center",
      menuItems: [
        {
          value: "left",
          label: "left",
        },
        {
          value: "center",
          label: "center",
        },
        {
          value: "right",
          label: "right",
        },
      ],
      helperText:
        "Where the tour should be placed horizontally, if it doesn't use up 100% of the width",
      requiresReload: false,
      tourbuilderFunction: "horizontallyAlignImage",
    },
  ],
  setControls: null,
}
export const controlsContext = createContext<controlsContextProps>(
  defaultControlsContext
);
let rowAmount;
const portWidth = window.innerWidth;

if (portWidth <= 600) {
  rowAmount = 3;
} else if (portWidth <= 1280) {
  rowAmount = 2;
} else {
  rowAmount = 1;
}
export const controlTabContext = createContext({
  active: "tour",
  height:
    document.body.getBoundingClientRect().height -
    77 * rowAmount -
    48 -
    76.84 +
    "px",
});
