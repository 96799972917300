/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
import React, { useEffect, useContext, useState } from "react";
import { Grid, Tooltip, Paper } from "@material-ui/core";
import {
  tour,
  initTour,
  initTourVar,
  updatePano,
  getFunctionFromString,
} from "../../pano";
import {
  ContainerNameType,
  controlsContext,
  controlsContextProps,
  tourParamsContext,
} from "../../context";
import { getParamsFromControls } from "../../helper";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import StopRoundedIcon from "@material-ui/icons/StopRounded";
import CodeIcon from "@material-ui/icons/Code";
import { Control, ControlType, SelectControl } from "../../types";

declare var window: any;

const PanoPreview: React.FC<{}> = ({}) => {
  const controls = useContext(controlsContext);
  const tourParams = useContext(tourParamsContext);
  const [moving, setMoving] = useState(false);
  const getParamById = (id: string, ref: ContainerNameType) => {
    return controls[ref].filter((control) => control.id === id)[0]?.value;
  };
  const getInitParams = () => {
    return {
      basepath: getParamById("basepath", "tour"),
      node: getParamById("node", "tour"),
      fov: getParamById("fov_target", "tour"),
      tilt: getParamById("tilt_target", "tour"),
      pan: getParamById("pan_target", "tour"),
      height: getParamById("tour_height", "tourStyle"),
      width: getParamById("tour_width", "tourStyle"),
      horizontalAlignment: getParamById("horizontal_alignment", "tourStyle"),
    };
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let classInterval = setInterval(() => {
      if (window.Pano_360ty) {
        // eslint-disable-next-line no-undef
        clearInterval(classInterval);
        if (!tour) {
          initTourVar("360ty_pano_container", window.Pano_360ty);
          let params = getInitParams();
          initTour(params);
          /*
          const newControls: controlsContextProps = {
            addons: [],
            hotspotStyle: [],
            movement: [],
            tour: [],
            tourStyle: [],
            setControls: null,
          };
          // assign the function names of controls to functions once the tour variable has been initialized
          Object.keys(controls).forEach((key) => {
            if (key !== "setControls") {
              controls[key as ContainerNameType].forEach((control, i) => {
                if (typeof control.tourbuilderFunction === "string") {
                  const func = getFunctionFromString(
                    control.tourbuilderFunction
                  );
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  newControls[key as ContainerNameType][i] = {
                    ...control,
                    tourbuilderFunction:
                      typeof func === "function"
                        ? func
                        : control.tourbuilderFunction,
                  };
                } else {
                  newControls[key as ContainerNameType][i] = control;
                }
              });
            }
          });
          if (controls.setControls) {
            newControls.setControls = controls.setControls;
            controls.setControls(newControls);
          }
        }*/
      }
      if (tour)
        tour.onMovementAborted = () => {
          setMoving(false);
          if (tour) tour.movement_params.movementAborted = false;
        };
      if (tour)
        tour.onMovementFinished = () => {
          setMoving(false);
        };
      }
    }, 50);
  }, []);
  useEffect(() => {
    updatePano(getParamsFromControls(controls, tourParams.active_viewport));
  }, [controls, tourParams.active_viewport]);

  useEffect(() => {
    if (tour && tour.movement_params && tour.movement_params.keyframes) {
      tour.movement_params.keyframes = tourParams.keyframes;
    }
  }, [tourParams.keyframes]);

  let height_tablet = window.visualViewport
    ? window.visualViewport.height - 70 - 160
    : window.innerHeight - 70 - 160;
  let width_tablet = height_tablet * 0.75;

  let height_mobile = height_tablet;
  let width_mobile = height_mobile * 0.56;

  let desktop_styles = {
    borderRadius: 0,
    width: "100%",
    overflow: "auto",
    backgroundColor: "#121212",
  };

  let tablet_styles = {
    width: width_tablet + "px",
    height: height_tablet + "px",
    overflow: "auto",
    marginTop: "80px",
    marginBottom: "80px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#121212",
  };

  let mobile_styles = {
    width: width_mobile + "px",
    height: height_mobile + "px",
    overflow: "auto",
    marginTop: "80px",
    marginBottom: "80px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#121212",
  };
  const iconStyle = {
    fontSize: "35px",
    margin: 0,
    cursor: "pointer",
    borderStyle: "solid",
    borderRadius: "999px",
    borderWidth: "5px",
    borderColor: "#fff",
  };

  return (
    <Grid
      item
      xs={12}
      style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
    >
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <Paper
          style={
            tourParams.active_viewport === "desktop"
              ? desktop_styles
              : tourParams.active_viewport === "tablet"
              ? tablet_styles
              : mobile_styles
          }
        >
          <div
            style={{
              position: "absolute",
              margin: "16px",
              zIndex: 5,
              color: "#f40000",
            }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                {moving ? (
                  <Tooltip title="Stop Movement">
                    <StopRoundedIcon
                      style={iconStyle}
                      onClick={() => {
                        if (tour) tour.movement_params.movementAborted = true;
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      tourParams.keyframes.length !== 0
                        ? "Run Movement"
                        : "Add Keyframes in the Movement Tab before running the Movement"
                    }
                  >
                    <PlayArrowRoundedIcon
                      style={iconStyle}
                      onClick={() => {
                        if (
                          tour &&
                          tour.pano &&
                          tourParams.keyframes.length !== 0
                        ) {
                          tour.setActiveNode(getParamById("node", "tour"));
                          tour.setActiveFov(getParamById("fov_target", "tour"));
                          tour.setActiveTilt(
                            getParamById("tilt_target", "tour")
                          );
                          tour.setActivePan(getParamById("pan_target", "tour"));
                          tour.movement_params.movementAborted = false;
                          tour.setMovementLoopAmount(
                            getParamById("loop_amount", "movement")
                          );
                          // eslint-disable-next-line no-undef
                          setTimeout(() => {
                            if (tour) tour.loadKeyframes();
                          }, getParamById("delay", "movement"));
                          setMoving(true);
                        }
                      }}
                    />
                  </Tooltip>
                )}
              </Grid>
              <Grid item>
                <Tooltip title="show embed code">
                  <CodeIcon
                    style={iconStyle}
                    onClick={() => {
                      if (tourParams.setTourParams)
                        tourParams.setTourParams({
                          ...tourParams,
                          codePanelOpen: true,
                        });
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </div>
          <div id="360ty_pano_container" style={{ overflow: "auto" }}></div>
        </Paper>
      </div>
    </Grid>
  );
};

export default PanoPreview;
