import React, { useContext } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, InputLabel, Select, MenuItem } from "@material-ui/core";
import TextfieldInput from "../Inputs/TextfieldInput";
import { tourParamsContext, controlsContext } from "../../context";
import { setResponsiveControlValue } from "../../helper";
import { tour } from "../../pano";
import { SelectControl } from "../../types";

const TourStyleControls: React.FC = () => {
  const tourParams = useContext(tourParamsContext);
  const controls = useContext(controlsContext);
  const containerName = "tourStyle";

  return (
    <div>
      {controls.tourStyle.map((control, i) => {
        switch (control.InputType) {
          case "textfield":
            return (
              <TextfieldInput
                key={control.id}
                control={control}
                containerName={containerName}
              />
            );
          case "select":
            return (
              <div key={control.id}>
                <Grid container style={{ padding: "10px" }}>
                  <Grid item xs>
                    <InputLabel id={"tour_style_control" + i + "_select"}>
                      {control.label}
                    </InputLabel>
                    <Select
                      value={
                        tourParams.active_viewport === "desktop"
                          ? control.value
                          : tourParams.active_viewport === "tablet"
                          ? control.value_tablet
                          : control.value_mobile
                      }
                      onChange={(event) => {
                        setResponsiveControlValue(
                          control,
                          event.target.value,
                          tourParams.active_viewport,
                          containerName,
                          controls,
                          controls.setControls,
                          tour
                        );
                      }}
                    >
                      {(control as SelectControl).menuItems.map((item, i) => {
                        return (
                          <MenuItem key={i} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
                <div
                  style={
                    (control.value === "custom_ratio" &&
                      tourParams.active_viewport === "desktop") ||
                    (control.value_tablet === "custom_ratio" &&
                      tourParams.active_viewport === "tablet") ||
                    (control.value_mobile === "custom_ratio" &&
                      tourParams.active_viewport === "mobile")
                      ? { display: "" }
                      : { display: "none" }
                  }
                >
                  <Grid container style={{ padding: "10px" }}>
                    <Grid item xs>
                      <TextField
                        label="Ratio"
                        value={
                          tourParams.active_viewport === "desktop"
                            ? (control as SelectControl).custom_ratio
                            : tourParams.active_viewport === "tablet"
                            ? (control as SelectControl).custom_ratio_tablet
                            : (control as SelectControl).custom_ratio_mobile
                        }
                        onChange={(event) => {
                          setResponsiveControlValue(
                            control,
                            event.target.value,
                            tourParams.active_viewport,
                            containerName,
                            controls,
                            controls.setControls,
                            tour
                          );
                        }}
                        helperText="width:height"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={
                    (control.value === "custom_height" &&
                      tourParams.active_viewport === "desktop") ||
                    (control.value_tablet === "custom_height" &&
                      tourParams.active_viewport === "tablet") ||
                    (control.value_mobile === "custom_height" &&
                      tourParams.active_viewport === "mobile")
                      ? { display: "" }
                      : { display: "none" }
                  }
                >
                  <Grid container style={{ padding: "10px" }}>
                    <Grid item xs>
                      <TextField
                        label="Height"
                        onChange={(event) => {
                          setResponsiveControlValue(
                            control,
                            event.target.value,
                            tourParams.active_viewport,
                            containerName,
                            controls,
                            controls.setControls,
                            tour
                          );
                        }}
                        value={
                          tourParams.active_viewport === "desktop"
                            ? (control as SelectControl).custom_height
                            : tourParams.active_viewport === "tablet"
                            ? (control as SelectControl).custom_height_tablet
                            : (control as SelectControl).custom_height_mobile
                        }
                        helperText="uses CSS units (px,%,vh). Use %% to use x% of the tour width"
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            );
          default:
            console.error(`input type ${control.InputType} was not found`);
            return <p key={i}>input type {control.InputType} was not found</p>;
        }
      })}
    </div>
  );
};

export default TourStyleControls;
