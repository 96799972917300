/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatch, SetStateAction } from "react";
import {
  ActiveViewport,
  ContainerNameType,
  controlsContextProps,
  TourbuilderFuncs,
  tourParamsContextProps,
} from "./context";
import { ControlType, Tourbuilder } from "./types";

export const setTourControlValue = (
  control: ControlType,
  value: any,
  keyToSet: "value" | "value_mobile" | "value_tablet",
  ref: ContainerNameType,
  controls: controlsContextProps,
  setValues: React.Dispatch<React.SetStateAction<controlsContextProps>> | null,
  tour: Tourbuilder | null
) => {
  const newControls = [...controls[ref]];
  const index = newControls.findIndex(
    (newControl) => newControl.id === control.id
  );
  if (index !== -1) {
    newControls[index][keyToSet] = value;
    if (setValues) setValues({ ...controls, [ref]: newControls });
  }
};

export const setResponsiveControlValue = (
  control: ControlType,
  value: any,
  viewport: ActiveViewport,
  ref: ContainerNameType,
  controls: controlsContextProps,
  setValues: Dispatch<SetStateAction<controlsContextProps>> | null,
  tour: Tourbuilder | null
) => {
  const newControls = [...controls[ref]];
  const index = newControls.findIndex(
    (newControl) => newControl.id === control.id
  );
  const controlToMutate = newControls[index];
  if (typeof controlToMutate.value_mobile !== undefined) {
    switch (viewport) {
      case "desktop":
        controlToMutate.value = value;
        if (tour && control.tourbuilderFunction) {
          typeof tour[control.tourbuilderFunction] === "function"
            ? tour[control.tourbuilderFunction](
                // @ts-ignore
                value
              )
            : console.error(
                "tourbuilder doesnt have '" +
                  control.tourbuilderFunction +
                  "' method"
              );
          if (control.requiresReload) {
            tour.reload();
          }
        }
        break;
      case "tablet":
        if (tour && control.tourbuilderFunction) {
          controlToMutate.value_tablet = value;
          typeof tour[
            (control.tourbuilderFunction + "_tablet") as TourbuilderFuncs
          ] === "function"
            ? tour[
                (control.tourbuilderFunction + "_tablet") as TourbuilderFuncs
              ](
                // @ts-ignore
                value
              )
            : typeof tour[control.tourbuilderFunction] === "function"
            ? tour[control.tourbuilderFunction](
                // @ts-ignore
                value
              )
            : console.error(
                "tourbuilder doesnt have '" +
                  control.tourbuilderFunction +
                  "' method"
              );
          if (control.requiresReload) {
            tour.reload();
          }
        }
        break;
      case "mobile":
        if (tour && control.tourbuilderFunction) {
          controlToMutate.value_mobile = value;
          typeof tour[
            (control.tourbuilderFunction + "_mobile") as TourbuilderFuncs
          ] === "function"
            ? tour[
                (control.tourbuilderFunction + "_mobile") as TourbuilderFuncs
              ](
                // @ts-ignore
                value
              )
            : typeof tour[control.tourbuilderFunction] === "function"
            ? tour[control.tourbuilderFunction](
                // @ts-ignore
                value
              )
            : console.error(
                "tourbuilder doesnt have '" +
                  control.tourbuilderFunction +
                  "' method"
              );
          if (control.requiresReload) {
            tour.reload();
          }
        }
        break;
      default:
        console.error("viewport not found: " + viewport);
        break;
    }
  } else {
    controlToMutate.value = value;
    if (tour && control.tourbuilderFunction) {
      tour[control.tourbuilderFunction](
        // @ts-ignore
        value
      );
      if (control.requiresReload) {
        tour.reload();
      }
    }
  }
  if (setValues) setValues({ ...controls, [ref]: newControls });
};
export const getParamsFromControls = (
  controls: controlsContextProps,
  viewport: ActiveViewport = "desktop"
) => {
  const params: { [key: string]: any } = {};
  Object.keys(controls).forEach((controlSection) => {
    if (controlSection !== "setControls") {
      controls[controlSection as ContainerNameType].forEach((control) => {
        params[control.id] =
          viewport === "desktop"
            ? control.value
            : viewport === "tablet"
            ? control.value_tablet || control.value
            : control.value_mobile || control.value;
      });
    }
  });
  return params;
};
