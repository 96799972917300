import React, { useContext } from "react";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import TabletAndroidIcon from "@material-ui/icons/TabletAndroid";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { tourParamsContext } from "../../context";
import styled from "styled-components";

interface ActiveResponsiveIconProps {
  className?: string;
}

const ActiveResponsiveIconFunc: React.FC<ActiveResponsiveIconProps> = ({
  className,
}) => {
  const tourParams = useContext(tourParamsContext);
  return (
    <div className={className} data-testid="ResponsiveIcon-Wrapper">
      {tourParams.active_viewport === "desktop" ? (
        <DesktopMacIcon
          data-testid="ResponsiveIcon-desktop"
          color="primary"
          style={{ float: "right", padding: 0 }}
        />
      ) : tourParams.active_viewport === "tablet" ? (
        <TabletAndroidIcon
          data-testid="ResponsiveIcon-tablet"
          color="primary"
          style={{ float: "right", padding: 0 }}
        />
      ) : (
        <PhoneAndroidIcon
          data-testid="ResponsiveIcon-mobile"
          color="primary"
          style={{ float: "right", padding: 0 }}
        />
      )}
    </div>
  );
};

const ActiveResponsiveIcon = styled(ActiveResponsiveIconFunc)``;

export default ActiveResponsiveIcon;
