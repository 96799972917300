/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext } from "react";
import { Tabs, Tab, Grid, Typography } from "@material-ui/core";
import { responsiveTheme } from "../../Themes";
import { ThemeProvider } from "@material-ui/core/styles";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import TabletAndroidIcon from "@material-ui/icons/TabletAndroid";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import "./index.css";
import {
  tourParamsContext,
  controlsContext,
  TourbuilderFuncs,
} from "../../context";
import { tour } from "../../pano";

const ResponsiveSwitcher: React.FC = () => {
  const tourParams = useContext(tourParamsContext);
  const controls = useContext(controlsContext);
  return (
    <Grid
      container
      item
      id="ResponsiveSwitcher-container"
      xs={6}
      sm={4}
      md={3}
      lg={2}
    >
      <ThemeProvider theme={responsiveTheme}>
        <Grid
          item
          xs={4}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (tourParams.setTourParams) {
              tourParams.setTourParams({
                ...tourParams,
                active_viewport: "desktop",
              });
              controls.tourStyle.forEach((control) => {
                if (control.tourbuilderFunction) {
                  //@ts-ignore
                  tour[control.tourbuilderFunction](control.value);
                }
              });
              setTimeout(() => {
                controls.tourStyle.forEach((control) => {
                  if (control.tourbuilderFunction) {
                    //@ts-ignore
                    tour[control.tourbuilderFunction](control.value);
                  }
                });
              }, 10);
            }
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <DesktopMacIcon
                fontSize="small"
                style={{ verticalAlign: "middle" }}
                color={
                  tourParams.active_viewport === "desktop"
                    ? "primary"
                    : "secondary"
                }
              />
            </Grid>
            <Grid item>
              <Typography
                color={
                  tourParams.active_viewport === "desktop"
                    ? "primary"
                    : "secondary"
                }
                variant="body2"
                style={{ paddingTop: "4px" }}
              >
                Desktop
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (tourParams.setTourParams)
              tourParams.setTourParams({
                ...tourParams,
                active_viewport: "tablet",
              });
            if (tour) {
              controls.tourStyle.forEach((control) => {
                if (tour) {
                  typeof tour[
                    (control.tourbuilderFunction +
                      "_tablet") as TourbuilderFuncs
                  ] === "function"
                    ? tour[
                        (control.tourbuilderFunction +
                          "_tablet") as TourbuilderFuncs
                      ](
                        //@ts-ignore
                        control.value_tablet || control.value
                      )
                    : typeof tour[control.tourbuilderFunction || "void"] ===
                      "function"
                    ? tour[control.tourbuilderFunction || "void"](
                        //@ts-ignore
                        control.value_tablet || control.value
                      )
                    : console.error(
                        "tourbuilder doesnt have '" +
                          control.tourbuilderFunction +
                          "' method"
                      );
                }
              });
              setTimeout(() => {
                controls.tourStyle.forEach((control) => {
                  if (tour) {
                    typeof tour[
                      (control.tourbuilderFunction +
                        "_tablet") as TourbuilderFuncs
                    ] === "function"
                      ? tour[
                          (control.tourbuilderFunction +
                            "_tablet") as TourbuilderFuncs
                        ](
                          //@ts-ignore
                          control.value_tablet || control.value
                        )
                      : typeof tour[control.tourbuilderFunction || "void"] ===
                        "function"
                      ? tour[control.tourbuilderFunction || "void"](
                          //@ts-ignore
                          control.value_tablet || control.value
                        )
                      : console.error(
                          "tourbuilder doesnt have '" +
                            control.tourbuilderFunction +
                            "' method"
                        );
                  }
                });
              }, 10);
            }
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <TabletAndroidIcon
                fontSize="small"
                style={{ verticalAlign: "middle" }}
                color={
                  tourParams.active_viewport === "tablet"
                    ? "primary"
                    : "secondary"
                }
              />
            </Grid>
            <Grid item>
              <Typography
                color={
                  tourParams.active_viewport === "tablet"
                    ? "primary"
                    : "secondary"
                }
                variant="body2"
                style={{ paddingTop: "4px" }}
              >
                Tablet
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (tourParams.setTourParams)
              tourParams.setTourParams({
                ...tourParams,
                active_viewport: "mobile",
              });
            controls.tourStyle.forEach((control) => {
              if (tour) {
                typeof tour[
                  (control.tourbuilderFunction + "_mobile") as TourbuilderFuncs
                ] === "function"
                  ? tour[
                      (control.tourbuilderFunction +
                        "_mobile") as TourbuilderFuncs
                    ](
                      //@ts-ignore

                      control.value_mobile || control.value
                    )
                  : typeof tour[control.tourbuilderFunction || "void"] ===
                    "function"
                  ? tour[control.tourbuilderFunction || "void"](
                      //@ts-ignore

                      control.value_mobile || control.value
                    )
                  : console.error(
                      "tourbuilder doesnt have '" +
                        control.tourbuilderFunction +
                        "' method"
                    );
              }
            });
            setTimeout(() => {
              controls.tourStyle.forEach((control) => {
                if (tour) {
                  typeof tour[
                    (control.tourbuilderFunction +
                      "_mobile") as TourbuilderFuncs
                  ] === "function"
                    ? tour[
                        (control.tourbuilderFunction +
                          "_mobile") as TourbuilderFuncs
                      ](
                        //@ts-ignore
                        control.value_mobile || control.value
                      )
                    : typeof tour[control.tourbuilderFunction || "void"] ===
                      "function"
                    ? tour[control.tourbuilderFunction || "void"](
                        //@ts-ignore

                        control.value_mobile || control.value
                      )
                    : console.error(
                        "tourbuilder doesnt have '" +
                          control.tourbuilderFunction +
                          "' method"
                      );
                }
              });
            }, 10);
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <PhoneAndroidIcon
                fontSize="small"
                style={{ verticalAlign: "middle" }}
                color={
                  tourParams.active_viewport === "mobile"
                    ? "primary"
                    : "secondary"
                }
              />
            </Grid>
            <Grid item>
              <Typography
                color={
                  tourParams.active_viewport === "mobile"
                    ? "primary"
                    : "secondary"
                }
                variant="body2"
                style={{ paddingTop: "4px" }}
              >
                Mobile
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
};
export default ResponsiveSwitcher;
