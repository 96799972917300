import { createMuiTheme } from "@material-ui/core/styles";
const primary = "#F40000";
const secondary = "#FFFFFF";
const fontLight = "#c9c9c9";
const backgroundDark = "#1f1f1f";
const backgroundMid = "#2b2b2b";
const backgroundLight = "#404040";

export const controlsTheme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
  typography: {
    fontFamily: ["'Raleway'", "sans-serif"].join(","),
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        color: fontLight,
      },
    },
    MuiInputBase: {
      root: {
        color: secondary,
      },
    },
    MuiInputLabel: {
      root: {
        color: fontLight,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: backgroundDark,
      },
      rounded: {
        borderRadius: 0,
      },
    },
    MuiTabs: {
      root: {
        fontColor: secondary,
        backgroundColor: backgroundDark,
      },
      scrollButtons: {
        color: secondary,
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: secondary,
      },
    },
    MuiListItem: {
      root: {
        color: secondary,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: backgroundLight,
      },
    },
    MuiTypography: {
      h5: {
        cursor: "pointer",
        padding: "0.25em",
        textAlign: "center",
      },
      h6: {
        cursor: "pointer",
        padding: "0.25em",
        textAlign: "center",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: primary,
      },
    },
    MuiSvgIcon: {
      root: {
        margin: "0.5em",
        marginLeft: 0,
        color: primary,
      },
    },
    MuiButton: {
      root: {
        borderRadius: "50px",
        lineHeight: 2,
        marginTop: "1em",
        marginBottom: "1em",
      },
      label: {
        textTransform: "none",
        fontWeight: 600,
      },
    },
  },
});
export const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: backgroundMid,
    },
    secondary: {
      main: primary,
    },
  },
  typography: {
    fontFamily: ["'Raleway'", "sans-serif"].join(","),
  },
  overrides: {
    MuiTypography: {
      h4: {
        margin: "0.5em",
        textAlign: "center",
        cursor: "pointer",
      },
    },
    MuiAppBar: {},
    MuiSvgIcon: {
      root: {
        margin: "0.5em",
        marginLeft: 0,
      },
    },
  },
});
export const outputTheme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
  typography: {
    fontFamily: ["'Raleway'", "sans-serif"].join(","),
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: backgroundLight,
      },
    },
    MuiTypography: {
      h4: {
        color: secondary,
        textAlign: "center",
        margin: "0.5em",
      },
    },
  },
});

export const responsiveTheme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
  typography: {
    fontFamily: ["'Raleway'", "sans-serif"].join(","),
  },
  overrides: {
    MuiSvgIcon: {
      root: {},
    },
    MuiGrid: {
      item: {
        textAlign: "center",
        verticalAlign: "middle",
      },
    },
  },
});
