import React,{useContext} from 'react';
import {tour} from '../../../pano';
import {tourParamsContext,controlsContext, ContainerNameType} from '../../../context';
import {Grid, Divider, InputLabel, Input, FormHelperText} from '@material-ui/core';
import {setResponsiveControlValue} from '../../../helper'
import { NumberControl } from '../../../types';
import styled from 'styled-components';

interface NumberInputProps{
    className?:string;
    control: NumberControl;
    containerName:ContainerNameType;
}

const NumberInputFunc: React.FC<NumberInputProps> = ({className, control,containerName}) => {
    const tourParams = useContext(tourParamsContext);
    const controls = useContext(controlsContext);
    return (
        <Grid container className={className}>
            <Grid item xs>
                <InputLabel id={control.id+"_label"}>{control.label}</InputLabel>
                <Input
                    id={control.id}
                    onChange={(event)=>{
                        setResponsiveControlValue(control,parseFloat(event.target.value).toFixed(control.decimals),tourParams.active_viewport,containerName,controls,controls.setControls,tour);
                    }}
                    value = {tourParams.active_viewport === "desktop" ? control.value : (tourParams.active_viewport === "tablet" ? control.value_tablet || control.value : control.value_mobile || control.value)}
                    type = "number"
                />
                <FormHelperText>{control.helperText}</FormHelperText>
                <Divider/>
            </Grid>
        </Grid>
    )
}

const NumberInput = styled(NumberInputFunc)`
    padding: 10px;
`;

export default NumberInput;