import React, { useContext, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Popover, ThemeProvider, Typography } from "@material-ui/core";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { outputTheme } from "../../Themes";
import { controlsContext, tourParamsContext } from "../../context";

const OutputCode: React.FC = () => {
  const controls = useContext(controlsContext);
  const tourParams = useContext(tourParamsContext);
  const prefix = "instance";
  const parentID = "360ty_container";
  const tour_params: { [key: string]: any } = {};

  controls.tour.forEach((control) => {
    tour_params[control.id] = control;
  });
  const addon_params: { [key: string]: any } = {};
  controls.addons.forEach((control) => {
    addon_params[control.id] = control;
  });
  const movement_params: { [key: string]: any } = {};
  controls.movement.forEach((control) => {
    movement_params[control.id] = control;
  });
  const tour_style_params: { [key: string]: any } = {};
  let tour_height: { [key: string]: any } = {};
  controls.tourStyle.forEach((control) => {
    if (control.id === "tour_height") {
      tour_height = control;
    } else {
      tour_style_params[control.id] = control;
    }
  });
  const hotspot_style_params: { [key: string]: any } = {};
  controls.hotspotStyle.forEach((control) => {
    hotspot_style_params[control.id] = control;
  });
  let tourheight;
  if (tour_height.value === "custom_ratio") {
    tourheight = tour_height.custom_ratio;
  } else if (tour_height.value === "custom_height") {
    tourheight = tour_height.custom_height;
  } else {
    tourheight = tour_height.value;
  }
  let tourheight_tablet;
  if (tour_height.value_tablet === "custom_ratio") {
    tourheight_tablet = tour_height.custom_ratio_tablet;
  } else if (tour_height.value_tablet === "custom_height") {
    tourheight_tablet = tour_height.custom_height_tablet;
  } else {
    tourheight_tablet = tour_height.value_tablet;
  }
  let tourheight_mobile;
  if (tour_height.value_mobile === "custom_ratio") {
    tourheight_mobile = tour_height.custom_ratio_mobile;
  } else if (tour_height.value_mobile === "custom_height") {
    tourheight_mobile = tour_height.custom_height_mobile;
  } else {
    tourheight_mobile = tour_height.value_mobile;
  }
  const styleString =
    '<link rel="stylesheet" href="https://storage.googleapis.com/api.360ty.cloud/360ty_styles.css">';
  const scriptString = `<script src="https://storage.googleapis.com/api.360ty.cloud/Tourbuilder%20webapp/class-tourbuilder-360ty.js"></script>
`;
  const divString = `<div id="${parentID}"></div>
`;
  const instanceString = `var tour_360ty = new Pano_360ty("${parentID}","${tour_params.basepath.value}");
    `;
  const dimensionString = `tour_360ty.setDimensions("${tour_style_params.tour_width.value}","${tourheight}");
    `;
  const horAlignString = `tour_360ty.setHorizontalAlignment("${tour_style_params.horizontal_alignment.value}");
    `;
  const nodeString = `tour_360ty.setStartNode(${tour_params.node.value});
    `;
  const startViewString = `tour_360ty.setViewingParameter(${tour_params.fov_target.value},${tour_params.tilt_target.value},${tour_params.pan_target.value});
    `;
  const singleImageString = `tour_360ty.setSingleImage(${addon_params.single_image.value});
    `;
  const shareButtonsString = `tour_360ty.setShareButtonVisibility(${addon_params.share_buttons.value});
    `;
  const impressumString = `tour_360ty.setImpressumVisibility(${addon_params.show_impressum.value});
    `;
  const skinVarsString = `tour_360ty.setSkinVariables([{hotspotFarbe:"${hotspot_style_params.hotspot_color.value}"}]);
    `;
  const moveLoopString = `tour_360ty.setMovementLoopAmount(${movement_params.loop_amount.value});
    `;
  const moveDelayString = `tour_360ty.setMovementDelay(${movement_params.delay.value});
    `;
  const dimensionString_tablet = `tour_360ty.setDimensions_tablet("${tour_style_params.tour_width.value_tablet}","${tourheight_tablet}");
    `;
  const horAlignString_tablet = `tour_360ty.setHorizontalAlignment_tablet("${tour_style_params.horizontal_alignment.value_tablet}");
    `;
  const singleImageString_tablet = `tour_360ty.setSingleImage_tablet(${addon_params.single_image.value_tablet});
    `;
  const shareButtonsString_tablet = `tour_360ty.setShareButtonVisibility_tablet(${addon_params.share_buttons.value_tablet});
    `;
  const impressumString_tablet = `tour_360ty.setImpressumVisibility_tablet(${addon_params.show_impressum.value_tablet});
    `;
  const dimensionString_mobile = `tour_360ty.setDimensions_mobile("${tour_style_params.tour_width.value_mobile}","${tourheight_mobile}");
    `;
  const horAlignString_mobile = `tour_360ty.setHorizontalAlignment_mobile("${tour_style_params.horizontal_alignment.value_mobile}");
    `;
  const singleImageString_mobile = `tour_360ty.setSingleImage_mobile(${addon_params.single_image.value_mobile});
    `;
  const shareButtonsString_mobile = `tour_360ty.setShareButtonVisibility_mobile(${addon_params.share_buttons.value_mobile});
    `;
  const impressumString_mobile = `tour_360ty.setImpressumVisibility_mobile(${addon_params.show_impressum.value_mobile});
    `;

  let keyFrameString = ``;
  tourParams.keyframes.forEach((keyframe) => {
    keyFrameString =
      keyFrameString +
      `tour_360ty.addKeyframe(${keyframe.fov},${keyframe.tilt},${keyframe.pan},${keyframe.speed},"${keyframe.move_lock}",${keyframe.node});
    `;
  });
  const initString = `tour_360ty.init();`;
  const bodyString = `
${divString}
${scriptString}
<script>
    ${
      instanceString +
      dimensionString +
      horAlignString +
      nodeString +
      startViewString +
      singleImageString +
      shareButtonsString +
      impressumString +
      dimensionString_tablet +
      horAlignString_tablet +
      singleImageString_tablet +
      shareButtonsString_tablet +
      impressumString_tablet +
      dimensionString_mobile +
      horAlignString_mobile +
      singleImageString_mobile +
      shareButtonsString_mobile +
      impressumString_mobile +
      (hotspot_style_params.hotspot_color.value !== "" ? skinVarsString : "") +
      (movement_params.loop_amount.value !== 1 ? moveLoopString : "") +
      (movement_params.delay.value !== 0 ? moveDelayString : "") +
      (tourParams.keyframes.length !== 0 ? keyFrameString : "") +
      initString
    }
</script>
`;
  const headString = `
${styleString}
`;
  const [headCodeCopied, setHeadCodeCopied] = useState(false);
  const [bodyCodeCopied, setBodyCodeCopied] = useState(false);
  return (
    <div style={{ overflow: "hidden" }}>
      <ThemeProvider theme={outputTheme}>
        <Popover
          id={"outputCode"}
          open={tourParams.codePanelOpen}
          anchorEl={document.body}
          onClose={() => {
            if (tourParams.setTourParams)
              tourParams.setTourParams({ ...tourParams, codePanelOpen: false });
            setHeadCodeCopied(false);
            setBodyCodeCopied(false);
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <Typography variant="h4" color="secondary" align="center">
            Head
          </Typography>
          <div style={{ whiteSpace: "pre-wrap" }}>
            <CopyToClipboard
              text={headString}
              onCopy={() => {
                setHeadCodeCopied(true);
                setTimeout(() => {
                  setHeadCodeCopied(false);
                }, 2500);
              }}
            >
              {headCodeCopied === false ? (
                <FileCopyOutlinedIcon
                  style={{
                    position: "absolute",
                    right: 0,
                    margin: "4px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              ) : (
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    margin: "4px",
                    cursor: "pointer",
                  }}
                >
                  <CheckCircleOutlinedIcon
                    style={{ color: "green", float: "right" }}
                  />
                </div>
              )}
            </CopyToClipboard>

            <SyntaxHighlighter language="htmlbars" style={a11yDark}>
              {headString}
            </SyntaxHighlighter>
          </div>
          <Typography variant="h4" color="secondary" align="center">
            Body
          </Typography>
          <div style={{ whiteSpace: "pre-wrap" }}>
            <CopyToClipboard
              text={bodyString}
              onCopy={() => {
                setBodyCodeCopied(true);
                setTimeout(() => {
                  setBodyCodeCopied(false);
                }, 2500);
              }}
            >
              {bodyCodeCopied === false ? (
                <FileCopyOutlinedIcon
                  style={{
                    position: "absolute",
                    right: 0,
                    margin: "4px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              ) : (
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    margin: "4px",
                    cursor: "pointer",
                  }}
                >
                  <CheckCircleOutlinedIcon
                    style={{ color: "green", float: "right" }}
                  />
                </div>
              )}
            </CopyToClipboard>
            <SyntaxHighlighter language="htmlbars" style={a11yDark}>
              {bodyString}
            </SyntaxHighlighter>
          </div>
        </Popover>
      </ThemeProvider>
    </div>

    /*<Paper>
                <Grid container >
                    <Grid item xs={12}>
                        <h1>Code</h1>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                       
                    </Grid>
                </Grid>
            </Paper>*/
  );
};

export default OutputCode;
