import React, { useContext } from "react";
import { tour } from "../../../pano";
import {
  tourParamsContext,
  controlsContext,
  ContainerNameType,
} from "../../../context";
import {
  Grid,
  Checkbox,
  Divider,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import { setResponsiveControlValue } from "../../../helper";
import styled from "styled-components";
import { Control } from "../../../types";

interface CheckboxInputProps {
  className?: string;
  control: Control;
  containerName: ContainerNameType;
}

const CheckboxInputFunc: React.FC<CheckboxInputProps> = ({
  className,
  control,
  containerName,
}) => {
  const tourParams = useContext(tourParamsContext);
  const controls = useContext(controlsContext);
  return (
    <Grid
      data-testid="CheckboxInput-Wrapper"
      container
      style={{ padding: "10px" }}
    >
      <Grid item xs>
        <Checkbox
          data-testid="CheckboxInput-input"
          id={control.id}
          onChange={(event) => {
            setResponsiveControlValue(
              control,
              event.target.checked,
              tourParams.active_viewport,
              containerName,
              controls,
              controls.setControls,
              tour
            );
          }}
          checked={
            tourParams.active_viewport === "desktop"
              ? control.value
              : tourParams.active_viewport === "tablet"
              ? control.value_tablet
              : control.value_mobile
          }
          color="primary"
        />
        <InputLabel
          className="MuiFormLabel-root MuiInputLabel-root"
          style={{ display: "inline-block" }}
        >
          {control.label}
        </InputLabel>
        <FormHelperText>{control.helperText}</FormHelperText>
        <Divider />
      </Grid>
    </Grid>
  );
};

const CheckboxInput = styled(CheckboxInputFunc)`
  padding: 10px;
`;

export default CheckboxInput;
