import React from "react";
import { AlgoliaHit } from "../../types";

interface SearchItemProps {
  hit: AlgoliaHit;
  isSelected?: boolean;
}

const SearchItem: React.FC<SearchItemProps> = ({ isSelected, hit }) => {
  if (hit.basepath) {
    return (
      <div className="hit">
        <div className="hit-name">
          <p data-attribute="tour" data-hit={hit.tour}>
            {hit.tour.includes(".tour")
              ? hit.tour.substring(0, hit.tour.indexOf(".tour"))
              : hit.tour.substring(0, hit.tour.indexOf(".360ty"))}
          </p>
        </div>
        <div className="hit-description">
          <small data-attribute="basepath" data-hit={hit.basepath}>
            {hit.basepath}
          </small>
        </div>
      </div>
    );
  } else {
    return <div className="hidden" style={{ display: "none" }} />;
  }
};

export default SearchItem;
