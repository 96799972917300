/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useContext, FormEvent } from "react";
import SearchItem from "../SearchItem";
import algoliasearch from "algoliasearch";
import "./index.scss";
import {
  Grid,
  TextField,
  Typography,
  InputLabel,
  Button,
} from "@material-ui/core";
// @ts-ignore
import Autocomplete from "algolia-react-autocomplete";
import "algolia-react-autocomplete/build/css/index.css";
import CloseIcon from "@material-ui/icons/Close";
import { controlsContext } from "../../context";
import { tour } from "../../pano";
import { setResponsiveControlValue } from "../../helper";
import { AlgoliaHit, Control } from "../../types";

const client = algoliasearch("ERXSFIJXTC", "52fe8b96fc6ea9bf76196aa1eb7bec0f");
const indexes = [
  {
    source: client.initIndex("tours"),
    displayKey: "name",
    templates: {
      header: () => (
        <div style={{ width: "100%", display: "block" }}>
          <Typography
            style={{ display: "inline-block", padding: "12px" }}
            variant="h6"
          >
            {" "}
            Tour-search
          </Typography>
          <CloseIcon
            style={{
              display: "inline-block",
              float: "right",
              padding: "6px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              const el: HTMLDivElement | null | undefined = (
                e.target as HTMLDivElement
              ).parentElement?.parentElement as HTMLDivElement;
              if (el) {
                el.style.display = "none";
              }
            }}
          />
        </div>
      ),
      suggestion: (suggestion: AlgoliaHit, isSelected: boolean) => (
        <SearchItem isSelected={isSelected} hit={suggestion} />
      ),
    },
  },
];
const TourSearchInput: React.FC = () => {
  const controls = useContext(controlsContext);

  const handleSuggestionSelection = (selectedSuggestion: AlgoliaHit) => {
    if (selectedSuggestion.basepath) {
      const basepathControl = controls.tour.filter(
        (control) => control.id === "basepath"
      )[0];
      setResponsiveControlValue(
        basepathControl,
        selectedSuggestion.basepath,
        "desktop",
        "tour",
        controls,
        controls.setControls,
        tour
      );
    }
  };
  const [customBasepath, setCustomBasepath] = useState(false);
  const [customBasepathInput, setCustomBasepathInput] = useState("");
  const onSubmit = (e: FormEvent<HTMLDivElement>) => {
    const basepathControl = controls.tour.filter(
      (control) => control.id === "basepath"
    )[0];
    setResponsiveControlValue(
      basepathControl,
      customBasepathInput,
      "desktop",
      "tour",
      controls,
      controls.setControls,
      tour
    );
  };
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <InputLabel style={{ marginTop: "8px" }}>Basepath</InputLabel>

      <Grid
        container
        direction="row"
        style={{ justifyContent: "space-around" }}
      >
        <Grid
          style={{ padding: "12px 0" }}
          item
          xs={8}
          sm={7}
          md={7}
          lg={8}
          xl={9}
        >
          {customBasepath ? (
            <TextField
              variant="outlined"
              style={{ width: "100%" }}
              color="primary"
              label="Press enter to confirm"
              value={customBasepathInput}
              onChange={(e) => {
                setCustomBasepathInput(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSubmit(e);
                }
              }}
              onSubmit={(e) => {
                onSubmit(e);
              }}
            />
          ) : (
            <Autocomplete
              indexes={indexes}
              onSelectionChange={(selectedSuggestion: AlgoliaHit) =>
                handleSuggestionSelection(selectedSuggestion)
              }
              style={{ width: "100%" }}
            >
              <input
                key="input"
                type="search"
                className="aa-input-search"
                autoComplete="off"
                onClick={(e) => {
                  const el: HTMLDivElement | null | undefined = (
                    e.target as HTMLDivElement
                  ).parentElement?.parentElement?.querySelector(
                    ".aa-dropdown-menus"
                  );
                  if (el) {
                    el.style.display = "";
                    const dropdownMenu: HTMLDivElement | null =
                      el.querySelector(".aa-dropdown-menu");
                    if (dropdownMenu) {
                      dropdownMenu.style.display = "";
                    }
                  }
                }}
                onSubmit={(e) => onSubmit}
              />
            </Autocomplete>
          )}
        </Grid>
        <Grid item style={{ alignSelf: "center" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setCustomBasepath(!customBasepath);
            }}
          >
            {customBasepath ? "search" : "custom"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: "8px" }}>
            current:{" "}
            {controls.tour.filter((control) => control.id === "basepath")[0]
              .value || ""}
          </InputLabel>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TourSearchInput;
