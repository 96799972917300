/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext } from "react";
import {
  Paper,
  Button,
  Grid,
  Slider,
  Input,
  Divider,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { tourParamsContext } from "../../context";
import { tour } from "../../pano";
import { Keyframe, KeyframeKeys } from "../../types";
import styled from "styled-components";

interface KeyframesProps {
  className?: string;
}

const KeyframesFunc: React.FC<KeyframesProps> = ({ className }) => {
  const tourParams = useContext(tourParamsContext);
  const changeKeyframeValue = (id: string, key: KeyframeKeys, value: any) => {
    const newKeyframes = [...tourParams.keyframes];
    const index = newKeyframes.findIndex(
      (newKeyframe) => newKeyframe.id === id
    );
    if (index !== -1) {
      // @ts-ignore
      newKeyframes[index][key] = value;
      if (tourParams.setTourParams)
        tourParams.setTourParams({ ...tourParams, keyframes: newKeyframes });
    }
  };
  return (
    <div className={className}>
      {tourParams.keyframes.map((keyframe, i) => (
        <Paper key={i}>
          <Grid container style={{ marginLeft: "1em" }}>
            <Grid item xs={2}>
              {keyframe.active === true ? (
                <ArrowDropDownIcon
                  color="secondary"
                  style={{ float: "right", padding: 0 }}
                />
              ) : (
                <ArrowRightIcon
                  color="secondary"
                  style={{ float: "right", padding: 0 }}
                />
              )}
            </Grid>

            <Grid item xs={8}>
              <Typography
                style={{ margin: "0.25em", marginLeft: 0 }}
                onClick={() => {
                  const newKeyframes = [...tourParams.keyframes];
                  const index = newKeyframes.findIndex(
                    (newKeyframe) => newKeyframe.id === keyframe.id
                  );
                  newKeyframes[index].active = !newKeyframes[index].active;
                  if (tourParams.setTourParams)
                    tourParams.setTourParams({
                      ...tourParams,
                      keyframes: newKeyframes,
                    });
                }}
                variant="h6"
                color="secondary"
                align="left"
              >
                Keyframe {i + 1}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <DeleteForeverIcon
                onClick={() => {
                  const newKeyframes = [...tourParams.keyframes].filter(
                    (newKeyframe) => newKeyframe.id !== keyframe.id
                  );
                  if (tourParams.setTourParams)
                    tourParams.setTourParams({
                      ...tourParams,
                      keyframes: newKeyframes,
                    });
                }}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Divider />
          <div
            style={
              keyframe.active === true ? { display: "" } : { display: "none" }
            }
          >
            <Button
              style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (tour && tour.pano) {
                  const newKeyframe: Keyframe = {
                    id: Math.random().toString(36).slice(2),
                    active: true,
                    fov: parseFloat(tour.pano.getFov().toFixed(2)),
                    tilt: parseFloat(tour.pano.getTilt().toFixed(2)),
                    pan: parseFloat(tour.pano.getPan().toFixed(2)),
                    node: parseInt(tour.pano.getCurrentNode().substring(4)),
                    speed: 1,
                    move_lock: keyframe.move_lock,
                  };
                  const newKeyframes = [...tourParams.keyframes];
                  const index = newKeyframes.findIndex(
                    (newKeyframe) => newKeyframe.id === keyframe.id
                  );
                  newKeyframes[index] = { ...newKeyframe, id: keyframe.id };
                  if (tourParams.setTourParams)
                    tourParams.setTourParams({
                      ...tourParams,
                      keyframes: newKeyframes,
                    });
                }
              }}
            >
              Set to current Position
            </Button>
            <Divider />
            <Grid container style={{ padding: "10px" }}>
              <Grid item xs>
                <InputLabel id={"node" + i + "_number"}>Node</InputLabel>
                <Input
                  name={"node" + i}
                  onChange={(event) => {
                    changeKeyframeValue(
                      keyframe.id,
                      "node",
                      parseInt(event.target.value)
                    );
                  }}
                  value={keyframe.node}
                  type="number"
                />
                <Divider />
              </Grid>
            </Grid>
            <Grid container style={{ padding: "10px" }}>
              <Grid item xs>
                <InputLabel id={"fov" + i + "_slider"}>Fov</InputLabel>
                {/* @ts-ignore */}
                <Slider
                  name={"fov" + i}
                  onChange={(_event, val) => {
                    changeKeyframeValue(keyframe.id, "fov", val);
                  }}
                  value={keyframe.fov}
                  step={0.1}
                  min={5}
                  max={100}
                  type="number"
                  valueLabelDisplay="auto"
                />
                <Input
                  name={"fov" + i}
                  onChange={(event) => {
                    changeKeyframeValue(
                      keyframe.id,
                      "fov",
                      parseFloat(event.target.value)
                    );
                  }}
                  value={keyframe.fov}
                  type="number"
                />
                <Divider />
              </Grid>
            </Grid>
            <Grid container style={{ padding: "10px" }}>
              <Grid item xs>
                <InputLabel id={"tilt" + i + "_slider"}>Tilt</InputLabel>
                {/* @ts-ignore */}
                <Slider
                  name={"tilt" + i}
                  onChange={(_event, val) => {
                    changeKeyframeValue(keyframe.id, "tilt", val);
                  }}
                  value={keyframe.tilt}
                  step={0.1}
                  min={-90}
                  max={90}
                  type="number"
                  valueLabelDisplay="auto"
                />
                <Input
                  name={"tilt" + i}
                  onChange={(event) => {
                    changeKeyframeValue(
                      keyframe.id,
                      "tilt",
                      parseFloat(event.target.value)
                    );
                  }}
                  value={keyframe.tilt}
                  type="number"
                />
                <Divider />
              </Grid>
            </Grid>
            <Grid container style={{ padding: "10px" }}>
              <Grid item xs>
                <InputLabel id={"pan" + i + "_slider"}>Pan</InputLabel>
                {/* @ts-ignore */}
                <Slider
                  labelid={"pan" + i + "_slider"}
                  name={"pan" + i}
                  onChange={(_event, val) => {
                    changeKeyframeValue(keyframe.id, "pan", val);
                  }}
                  value={keyframe.pan}
                  step={0.1}
                  min={-360}
                  max={360}
                  type="number"
                  valueLabelDisplay="auto"
                />
                <Input
                  name={"pan" + i}
                  onChange={(event) => {
                    changeKeyframeValue(
                      keyframe.id,
                      "pan",
                      parseFloat(event.target.value)
                    );
                  }}
                  value={keyframe.pan}
                  type="number"
                />
                <Divider />
              </Grid>
            </Grid>
            <Grid container style={{ padding: "10px" }}>
              <Grid item xs>
                <InputLabel id={"speed" + i + "_slider"}>
                  Movement Speed
                </InputLabel>
                {/* @ts-ignore */}
                <Slider
                  name={"speed" + i}
                  onChange={(event, val) => {
                    changeKeyframeValue(keyframe.id, "speed", val);
                  }}
                  value={keyframe.speed}
                  step={0.1}
                  min={0.1}
                  max={10.0}
                  type="number"
                  valueLabelDisplay="auto"
                />
                <Input
                  name={"speed" + i}
                  onChange={(event) => {
                    changeKeyframeValue(
                      keyframe.id,
                      "speed",
                      parseFloat(event.target.value)
                    );
                  }}
                  value={keyframe.speed}
                  type="number"
                />
                <Divider />
              </Grid>
            </Grid>
            <Grid container style={{ padding: "10px" }}>
              <Grid item xs>
                <InputLabel id={"move_lock" + i + "_select"}>
                  Locked Controls
                </InputLabel>
                <Select
                  name={"pan" + i}
                  onChange={(event) => {
                    changeKeyframeValue(
                      keyframe.id,
                      "move_lock",
                      parseFloat(event.target.value as string)
                    );
                  }}
                  value={keyframe.move_lock}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="Mouse">Mouse</MenuItem>
                  <MenuItem value="Mousewheel">Mouswheel</MenuItem>
                  <MenuItem value="Keyboard">Keyboard</MenuItem>
                  <MenuItem value="Keyboard_Mousewheel">
                    Keyboard + Mousewheel
                  </MenuItem>
                </Select>
                <Divider />
              </Grid>
            </Grid>
          </div>
        </Paper>
      ))}
    </div>
  );
};

const Keyframes = styled(KeyframesFunc)`
`;
export default Keyframes;
